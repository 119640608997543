import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AppUser } from '../../core/model';
import * as moment from 'moment';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { HelperService } from '../../shared/helperService';
import { LoginsService } from '../../core/service/logins.service';
import { UsersService } from '../../core/service';
@Component({
  selector: 'app-auth-system',
  templateUrl: './auth-system.component.html',
  styleUrls: ['./auth-system.component.scss']
})
export class AuthSystemComponent implements OnInit {
  @Output() activeTabName = new EventEmitter<any>();
  @Input() type: any;
  @Input() isAuthPage = false;
  mobileNo = '';
  emailId = '';
  emailToken = '';
  emailVerify = false;
  rememberme = false;
  mobileVerify = false;
  otpVerify = false;
  register = false;
  otpNum1 = '';
  otpNum2 = '';
  otpNum3 = '';
  otpNum4 = '';
  firstName = '';
  lastName = ''
  birthDate: Date;
  panNo = '';
  otp = '';
  existDetail = false;
  otpFail = false;
  showMobileView = false;
  socialUser: any;
  isShowLogin: boolean;
  isForgetPass = false;
  isResetPass = false;
  public isLoggedin: boolean;
  showAuthentication: boolean = true;
  showOtpSection: boolean = true;
  password: any;
  isLoginSuccess = true;
  constructor(
    public helperService: HelperService,
    public loginsService: LoginsService,
    public usersService: UsersService,
  ) {
    // this.helperService.clearInfo();
    // this.helperService.logout();
  }

  ngOnInit() {
    //this.otpVerify = true;
    if (this.type == 'login') {
      this.isShowLogin = true;
    }
    if (this.type == 'register') {
      this.isShowLogin = false;
    }
    this.showMobileView = this.helperService.isMobile;

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      if (this.type == 'login') {
        this.isShowLogin = true;
      }
      if (this.type == 'register') {
        this.isShowLogin = false;
      }
      this.showMobileView = this.helperService.isMobile;
    }


  }

  route(path: string): void {
    this.helperService.routeNavigate(path);
  }

  async googleAuth() {
    const user = await GoogleAuth.signIn() as any;

    this.socialUser = user;
    this.isLoggedin = (user != null);
    this.emailId = user.email;
    this.emailToken = user.idToken;
    this.emailVerify = true;
    this.mobileVerify = false;
    this.validateEmail();

  }

  sendOtp() {
    if (this.mobileNo.length == 10) {
      this.loginsService.sendLoginOtp(this.mobileNo).subscribe(msg => {
        if (msg == "OTP sent") {
          this.otpVerify = true;
          this.otpFail = false;
        }
      });
    }

  }


  validateOtp() {
    this.otpFail = false;
    if (this.otpNum1 && this.otpNum2 && this.otpNum3 && this.otpNum4) {
      if (this.mobileNo.length == 10) {
        var otp = this.otpNum1 + this.otpNum2 + this.otpNum3 + this.otpNum4;

        this.loginsService.validateOtp(this.mobileNo, otp).subscribe(msg => {
          var msgVal = msg.split(':');
          this.otp = otp;
          if (msgVal[0] == "success") {
            const isNotRegisterd = msgVal[1] == "Not Register";
            if (isNotRegisterd) {
              this.emailVerify = false;
              this.mobileVerify = true;
              this.register = true;


            } else {
              //call for Login
              this.otpVerify = true;
              this.emailVerify = false;
              this.login();

            }
          } else {
            this.otpFail = true;
          }
        });
      }
    }

  }

  validateEmail() {
    this.loginsService.ValidateEmail(this.emailId, this.emailToken).subscribe(msg => {
      var msgVal = msg.split(':');
      if (msgVal[0] == "success") {
        const isNotRegisterd = msgVal[1] == "Not Register";
        if (isNotRegisterd) {
          this.emailVerify = true;
          this.mobileVerify = false;
          this.register = true;


        } else {
          //call for Login
          this.otpVerify = false;
          this.emailVerify = true;
          this.login();

        }
      }
    });

  }


  registerUser() {
    const user: AppUser = {
      name: this.firstName + ' ' + this.lastName,
      firstName: this.firstName,
      lastName: this.lastName,
      // birthDate: (moment(this.birthDate)).toDate(),
      // panNumber: this.panNo,
      mobile: this.mobileNo,
      emailId: this.emailId,
      emailVerified: this.emailVerify,
      isActive: true,
      isAdmin: false,
      hasImpersonateAccess: false
    }

    // this.usersService.register(user).subscribe(data => {

    //   if (data == "success") {
    //     this.login();
    //   } else {
    //     this.existDetail = true;
    //   }

    // })


  }
  // registerUser() {
  //   this.existDetail = false;
  //   const user: AppUser = {
  //     name: this.model.firstName + " " + this.model.lastName,
  //     firstName: this.model.firstName,
  //     lastName: this.model.lastName,
  //     mobile: this.model.mobile,
  //     emailId: this.model.emailId,
  //     password: this.model.password,
  //     emailVerified: true,
  //     role: "User",
  //     roleLabel: "User",
  //     isActive: true,
  //     isAdmin: false,
  //     hasImpersonateAccess: false,
  //   };

  //   this.usersService.register(user).subscribe((data) => {
  //     if (data == "success") {
  //       this.emailVerify = true;
  //       this.emailId = this.model.emailId;
  //       this.emailToken = this.model.password;
  //       this.login();
  //     } else {
  //       this.existDetail = true;
  //     }
  //   });
  // }

  login() {
    if (this.emailVerify || this.emailId) {
      this.loginsService.loginEmail(this.emailId, this.emailToken, this.rememberme).subscribe((data) => {
        this.setLoginDetals(data)
      });
    }
    else {
      this.loginsService.loginEmail(this.mobileNo, this.otp, this.rememberme).subscribe((data) => {
        this.setLoginDetals(data)
      });
    }


  }
  pinLogin() {
    if (this.emailId && this.password) {
      this.loginsService.loginPin(this.emailId, this.password).subscribe((data) => {
        if(data){
        this.setLoginDetals(data);
        this.isLoginSuccess =true;
        }
        else[
          this.isLoginSuccess = false
        ]
      });
    }


  }

  setLoginDetals(data) {
    if (data) {
      this.loginsService.helperSvc.setToken(data.token);
      this.helperService.setExpireToken(JSON.stringify(data.expireDate));
      this.helperService.setLoggedInUserInfo(JSON.stringify(data.userInfo));
      this.loginsService.helperSvc.routeNavigate('home');
      this.closePopup();
    }

  }
  closePopup() {
    this.activeTabName.emit(false);
  }
}
